@media (max-width: 600px) {
  .text-container {
    left: 30% !important;
    width: 200px !important;
    top: 56% !important;
  }

  .text-container h2 {
    font-size: 24px !important;
  }

  .text-container p {
    font-size: 12px !important;
    color: var(--grey-0, #fff);
  }

  .overlay-container {
    top: 5% !important;
    width: 180px !important;
    height: auto !important;
    left: 59% !important;
  }
}

.banner-content {
  display: flex;
}

.text-container {
  position: absolute;
  top: 50%;
  right: 60%;
  transform: translate(-50%, -50%);
  text-align: left;
  color: var(--primary-color-50, #df7f33);
  font-size: 20px !important;
  font-family: Montserrat;
  flex: 2;
  width: 383px;
}

.text-container h2 {
  color: var(--primary-color-50, #df7f33);
  font-size: 60px;
  font-family: Montserrat;
  font-weight: 900;
  line-height: 130%;
  letter-spacing: 0.9px;
}

.text-container p {
  font-size: 16px;
  color: var(--grey-0, #fff);
}

.overlay-container {
  position: absolute;
  top: 20%;
  width: 30%; /* Adjust the width as desired */
  height: 70%;
  z-index: 1;
  flex: 1;
  left: 65%;
}
.overlay-image {
  width: 80%;
  height: 80%;
}

.overlay-image-2 {
  width: 70%;
  position: absolute;
  top: 25%;
  left: 0%;
  animation-name: flyingAnimation;
  animation-duration: 7s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  opacity: 0;
}

@keyframes flyingAnimation {
  0% {
    transform: translateX(50%);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) rotate(20deg) scale(0.8);
    opacity: 1;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
