.payment-container {
  padding-bottom: 30px;
  background: var(--grey-10, #f2f2f2) !important;
}

@media (max-width: 600px) {
  .payment-container {
    padding-bottom: 30px;
    background: var(--grey-10, #f2f2f2) !important;
  }
}

.payment-card {
  background-color: #efbb40 !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}
