.content-figure {
  color: var(--primary-color-50, #df7f33) !important;
  text-align: center !important;
}

.content-figure h4 {
  color: var(--primary-color-50, #df7f33) !important;
  font-size: 20px !important;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.3px;
}

.content-figure p {
  color: var(--primary-color-50, #df7f33) !important;
}
