.game-container {
  background-image: url(/public/themes2/background.png);
}

.game-container h2 {
  color: var(--grey-90, #262626) !important;
}

.btn-wortel-primary-orange {
  color: var(--primary-color-50, #fff);

  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.24px;

  display: inline-flex;
  height: 37px;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 24px;
  background: var(--primary-color-50, #df7f33);
  border: #fff;
}

.btn-wortel-primary-orange:hover {
  color: #fff;

  /* Body Text 1/Medium */
  font-size: 16px;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.24px;

  display: inline-flex;
  height: 37px;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 24px;
  background: var(--primary-color-70, #964f18);
}
